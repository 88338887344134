import referenceProjects from './projects.json'
import type { MarketMakerRFQOption } from '../types/market-maker-rfq'
import { EngagementOptionEnum } from '@forgd/supabase'
import { LikertScaleEnum, type LikertScaleEnumType } from '../enums'

export const MAX_SELECTED_QUOTES = 5 // max number of quotes a user can select for requote or acceptance
export const MAX_QUOTES_SUBMITTED_PER_MARKET_MAKER_PER_ROUND = 3
export const MAX_DAYS_FOR_MARKET_MAKER_TO_SUBMIT_QUOTES = 7
export const MAX_DAYS_FOR_USER_TO_MAKE_DECISION_ON_QUOTES = 14

export const MMRFQExternalCapitalOptions: MarketMakerRFQOption[] = [
  { value: 'no_capital', label: 'No capital raised to date' },
  { value: '100K-500K', label: '$100,000 - $500,000' },
  { value: '500K-1000K', label: '$500,000 - $1,000,000' },
  { value: '1000K-2500K', label: '$1,000,000 - $2,500,000' },
  { value: '2500K-5000K', label: '$2,500,000 - $5,000,000' },
  { value: '5000K-10000K', label: '$5,000,000 - $10,000,000' },
  { value: 'more_than_10000K', label: 'More than $10,000,000' },
]

export const MMRFQMotivationsOptions: MarketMakerRFQOption[] = [
  {
    value: 'price_performance',
    label: 'Price Performance',
    description: '“I want to achieve the highest possible valuation (FDV).”',
  },
  {
    value: 'user_acquisition',
    label: 'User Acquisition',
    description: '“I want to acquire as many users as possible at a product / protocol level.”',
  },
  {
    value: 'treasury_management',
    label: 'Treasury Management',
    description: '“I want to liquidate tokens to increase its stablecoin reserves and extend the operational runway.”',
  },
  {
    value: 'liquidity_improvement',
    label: 'Liquidity Improvement',
    description: '“I want to ensure robust liquidity to facilitate efficient price discovery for our native token.”',
  },
]

export const MMRFQServiceOptions: MarketMakerRFQOption[] = [
  { value: 'Exchange Introductions', label: 'Exchange Introductions' },
  { value: 'Treasury Management', label: 'Treasury Management' },
  { value: 'OTC, TWAP Liquidation', label: 'Over-the-counter ("OTC") Trading' },
]

export const MMRFQEngagementOptions: Array<MarketMakerRFQOption & { engagementDescription: string }> = [
  {
    value: EngagementOptionEnum.LoanCallOption,
    label: 'Loan + Call Option',
    description:
      'Receives a loan of tokens with the option (but not obligation) to purchase tokens at some future date. Adheres to liquidity KPIs per legal contract.',
    engagementDescription:
      'This type of engagement utilizes a token loan from a project to provide liquidity.\n\nThe loan carries the optional (but not obligation) for the market maker to purchase tokens at some determined price.\n \n',
    loanRequirements: ['Tokens'],
  },
  {
    value: EngagementOptionEnum.RetainerWorkingCapital,
    label: 'Retainer + Working Capital',
    description:
      'Charges monthly fee to operate as a strategic liquidity provider. Trades using your capital (tokens & stablecoins). May receive a profit share.',
    engagementDescription:
      'This type of engagement utilizes both tokens and stablecoins belonging to a project to provide liquidity.\n\nThe market maker is incentivized via service fees and (potentially) profit share arrangements.',
    loanRequirements: ['Tokens', 'Stablecoins'],
  },
]

export const MMRFQTokenSupplyPercentageToAllocateOptions: MarketMakerRFQOption[] = [
  { value: '0.10-0.25', label: '0.10% - 0.25%' },
  { value: '0.25-0.50', label: '0.25% - 0.50%' },
  { value: '0.50-0.75', label: '0.50% - 0.75%' },
  { value: '0.75-1.00', label: '0.75% - 1.00%' },
  { value: '1.00-1.25', label: '1.00% - 1.25%' },
  { value: '1.25-1.50', label: '1.25% - 1.50%' },
  { value: '1.50-2.00', label: '1.50% - 2.00%' },
  { value: '2.00-3.00', label: '2.00% - 3.00%' },
  { value: '3.00-5.00', label: '3.00% - 5.00%' },
  { value: 'more_than_5.00', label: 'More than 5.00%' },
]

export const MMRFQStableCoinBudgetToAllocateOptions: MarketMakerRFQOption[] = [
  { value: 'unknown', label: "I don't know" },
  { value: '50K-100K', label: '$50K - $100K' },
  { value: '100K-150K', label: '$100K - $150K' },
  { value: '150K-250K', label: '$150K - $250K' },
  { value: '250K-500K', label: '$250K - $500K' },
  { value: '500K-1M', label: '$500K - $1M' },
  { value: '1M-2.5M', label: '$1M - $2.5M' },
  { value: '2.5M-5M', label: '$2.5M - $5M' },
  { value: '5M-7.5M', label: '$5M - $7.5M' },
  { value: '7.5M-10M', label: '$7.5M - $10M' },
]

export const MMRFQLikertScaleLabels: Record<LikertScaleEnumType, string> = {
  [LikertScaleEnum.StronglyDisagree]: 'Strongly Disagree',
  [LikertScaleEnum.Disagree]: 'Disagree',
  [LikertScaleEnum.Neutral]: 'Neutral',
  [LikertScaleEnum.Agree]: 'Agree',
  [LikertScaleEnum.StronglyAgree]: 'Strongly Agree',
}

export const MMRFQKpiTooltips = {
  '% of Quotes with Fees Charged per Exchange':
    'The percentage of “Retainer + Working Capital” quotes provided that charge a monthly fee per exchange covered.',
  '% of Quotes with Unlimited Exchange Coverage':
    'The percentage of “Retainer + Working Capital” quotes provided that provide coverage across an unlimited number of exchanges for a flat monthly fee.',
  'Avg. Order Book Dominance, Max %':
    'In the event that you represent greater than “XX%” of liquidity inside (+/-) 2.00% from midpoint, you will no longer have the obligation to maintain the indicated liquidity KPIs. Note, total liquidity is calculated across all centralized & decentralized venues the asset is traded on.',
  '% of Options Priced Within >6 Month Post-Inception':
    'On average, what percentage of tranches with a dynamic strike price will be priced after 6 months of the engagement commencing?',
  '% of Options Priced Within 1-6 Month Post-Inception':
    'On average, what percentage of tranches with a dynamic strike price will be priced within 1 - 6 months of the engagement commencing?',
  '% of Options Priced Within <1 Month Post-Inception':
    'On average, what percentage of tranches with a dynamic strike price will be priced within the first 1 month of the engagement commencing?',
  '% of Tranches Dynamic Price':
    'On average, what percentage of tranches within each loan carry a strike price that is “dynamic”? (Note: dynamic strike prices are calculated using a TWAP whereas fixed strike prices are denominated as a % relative to the spot price.)',
  '% of Tranches Fixed Price':
    'On average, what percentage of tranches within each loan carry a strike price that is “fixed”? (Note: fixed strike prices are denominated as a % relative to the spot price whereas dynamic strike prices are calculated using a TWAP.)',
  'Avg. Number of Tranches':
    'The average number of tranches per loan requested by Market Makers in this round of the RFQ.',
  'Avg. Interest Rate (APR, %)':
    'The average interest rate (offered to the project in exchange for the token loan) provided by Market Makers in this round of the RFQ.',
  'Avg. Token Loan Size (% of Total Supply)':
    'The average size of the loan requested by Market Makers in this round of the RFQ, denominated as a % of the total token supply.',
  'Number of European Options':
    'European options restrict the Market Maker and dictate they only have the right to exercise the option at contract expiration, at the agreed upon strike price.',
  'Number of American Options':
    'American options give the Market Maker a high degree of flexibility and offer them the right to exercise the option at any date during their loan contract, at the agreed upon strike price.',
  'Avg. Loan Tenor (Months)': `Loan tenor is the length of time remaining on a loan, or the amount of time until the loan is due. It's also known as the time-to-maturity of a loan.`,
  'Avg. Token Loan Size (USD)': 'The average size of the token loan, in US Dollar terms.',
  'Avg. Option Premium, per Quote':
    'The average premium of the strike prices at which each market maker has the option (but not obligation) to purchase tokens. Note, the average is value is taken per proposal, with each proposal potentially containing multiple strike prices depending on the number of tranches.',
  'Number of Quotes': 'Number of proposals received',
  'Avg. Token Loan Size (%)': `The average size of the token loan, as a % of your Token Supply.`,
  'Avg. Token Loan Size (Qty.)': `The average size of the token loan, as quantity of tokens.`,
  'Avg. Stablecoin Loan Size (USD)': 'The average size of the stablecoin loan, in US Dollar terms.',
  'Avg. Total Loan Size (USD)': 'The average size of the combined token & stablecoin loan, in US Dollar terms.',
  'Avg. Onboarding Fee (USD)':
    'The average fee charged by the market makers to configure strategies and begin coverage for your project, in US Dollar terms.',
  'Avg. Monthly Fee (USD)':
    'The monthly average fee charged by the market makers to maintain coverage for your project, in US Dollar terms. Note, if the market maker charges on a per exchange basis, Forgd assumes coverage on four exchanges when calculating their estimated monthly fee.',
  'Avg. Profit Share (%)':
    'The average percentage of total profit that will be allocated to market makers if they can successfully grow the value of your loaned capital. Note, market makers calculate profit in a variety of ways.',
}

export { referenceProjects }
